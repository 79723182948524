import React from 'react'
import styled from 'styled-components'
import InlineLink from '../components/InlineLink'

const Page = styled.div`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: 1fr;

    @media (min-width: 640px) {
        // grid-template-columns: 40vw calc(100% - 40vw);
        grid-template-columns: 2fr 3fr;
    }
`
const BannerImageBlock = styled.div`
    background-image: url('${props => props.src}');
    // background-repeat: no-repeat;
    background-size: cover;
    width: 100%
    height: auto;    
    grid-column: 1;
    @media (min-width: 640px) {
    }
`

const Banner = styled.div`
    // height: 48vw;
    // background-image: linear-gradient(to right, #6a1f01, c 20%, #ff9933);
    // background-image: linear-gradient(to right, #ff9933, #ff9933, 60%, papayawhip);
    // background-image: linear-gradient(to right, papayawhip, papayawhip, 70%, #ff9933);
    // color: papayawhip;

    border-top: solid;
    border-top-color: #6a1f01;
    border-top-width: 2px;
    border-bottom: solid;
    border-bottom-width: 2px;
    border-bottom-color: #ff9933;


    color: #6a1f01;
    grid-column: 1;
    padding: 0 1rem;
 
    h1 {
        // font-size: 5vw;
        font-size: calc(1.5rem + ((1vw - 0.2em) * 0.5));
        margin-bottom: 0.25em;

        font-weight: 600;
    }
    h2 {
        // font-size: 2.66vw;
        font-size: calc(1rem + ((1vw - 0.2em) * 0.5));
        // line-height: calc(0.5rem + ((1vw - 0.2em) * 8));

        font-weight: 500;
    }
    @media (min-width: 640px) {
        // height: 38vw;
        grid-column: 2;
        padding-left: 2rem;
        padding-right: 2rem;
        h1 {
            // font-size: 3vw;
            font-size: calc(1.5rem + ((1vw - 0.2em) * 0.5));
            margin-bottom: 0.5em;
        }
        h2 {
            // font-size: 2vw;
            font-size: calc(1rem + ((1vw - 0.2em) * 0.5));
            // line-height: 2em;
            line-height: calc(0.5rem + ((1vw - 0.2em) * 8));
        }
    }
`
const Body = styled.section`
    padding: 1rem 2rem;
    grid-column: 1 / 3;
    text-align: justify;
    margin: calc(0.5em + 1vw);

    h1 {
        font-size: 1.5em;
        margin-bottom: 0.25em;
        text-align: center;
        font-weight: 400;
    }
    h2 {
        color: #6a1f01;
        font-size: 1em;
        text-align: center;
        font-weight: 600;
    }

    @media (min-width: 640px) {
        padding: 1rem 2rem;
        h1 {
            font-size: 1.5em;
            margin-bottom: 0.5em;
        }
        h2 {
            font-size: 1em;
            line-height: 2em;
        }
    }
`
const CardContainer = styled.section`
    display: grid;
    grid-template-columns: 1;
    margin: 3em 0;
    align-self: center;

    @media (min-width: 530px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`

const BodyCard = styled.div`
    padding: 0em 0.5em;
    margin: 0.5em 0.5em;
    box-shadow: 0 0 5px rgba(0, 0, 0, .05), 0 0px 5px rgba(0, 0, 0, .08);
    border-radius: 5px;

    @media (min-width: 530px) {
        grid-template-columns: 1fr 1fr 1fr;
        margin: 0 0.5em;
    }
`

const BodyCardWrong = styled(BodyCard)`
    background-image: url('images/wrong.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-opacity: 0.5;
`

const BodyCardTitle = styled.p`
    color: #6a1f01;
    font-size: 1em;
    font-weight: 600;
    text-align: center;
    word-wrap: break-word;
`

const BodyCardText = styled.p`
    text-align: center;
    font-size: 1em;
`

const Home = () =>
    <Page>
        <Banner>
            <h1>IBM Sterling Order Management - onTap</h1>
            <h2>Implemented to your requirements</h2>
            <h2>Zero implementation costs</h2>
            <h2>Delivered in a SaaS model</h2>
        </Banner>
        <BannerImageBlock src='images/bar-wide-640w.jpg' />

        <Body>
            <h1>Experience a new way to have the industry leading Order Management software work for you</h1>

            <CardContainer>
                <BodyCard>
                    <BodyCardTitle>As a subscription service</BodyCardTitle>
                </BodyCard>
                <BodyCard>
                    <BodyCardTitle>Starting at $35K/month</BodyCardTitle>
                    <BodyCardText>Pre-determined, fixed cost</BodyCardText>
                </BodyCard>
                <BodyCard>
                    <BodyCardTitle>Have your SaaS, and customize it too!</BodyCardTitle>
                </BodyCard>
            </CardContainer>
            <p>Sterling onTap brings you all the benefits of SaaS without its downsides. At an astonishingly competitive price point.</p>

            <p>Price includes nearly everything except License costs. You will need to purchase a License for the software separately.</p>

            <p>If you aren't already in touch with IBM, we can help you with the License purchase from IBM.</p>

        </Body>

        <Banner>
            <h1>Stay focused on your business</h1>
            <h2>Fully implemented, managed and supported</h2>
            <h2>Continuous agile feature deployments</h2>
            <h2>Initial deployment in 4 to 8 weeks</h2>
        </Banner>
        <BannerImageBlock src='images/stay-focused.jpg' />
        <Body>
            <h1>Curb your Costs. Keep your Enthusiasm.</h1>

            <CardContainer>
                <BodyCard>
                    <BodyCardTitle>Cut your TCO in half</BodyCardTitle>
                </BodyCard>
                <BodyCard>
                    <BodyCardTitle>Leave software complexity to us</BodyCardTitle>
                </BodyCard>
                <BodyCard>
                    <BodyCardTitle>Enable Omni-channel</BodyCardTitle>
                </BodyCard>
            </CardContainer>

            <p>Created and brought to you by exceptionally skilled professionals that fundamentally repudiate the traditional approaches to implementing IBM Sterling Order Management.</p>

            <p>Curious? Take a look at <InlineLink to='/how'>our operating model</InlineLink>, and approach to manifesting functionality with agility.</p>
        </Body>
        <Banner>
            <h1>Power. Precision. Speed.</h1>
            <h2>Unleash the power of IBM Sterling</h2>
            <h2>Skillfully crafted solutions</h2>
            <h2>Reduced risk</h2>
        </Banner>

        {/* <BannerVideo autoPlay={true} loop={true} muted={true} playbackRate={0.2} >
            <source src='images/precision-at-speed.mp4' type='video/mp4'
            />
            Your browser does not support HTML5 video. Please visit us with a modern browser.
        </BannerVideo> */}
        <BannerImageBlock src='images/precision.jpg' />

        <Body>
            <h1>We do it right. Without the fuss. Or the fluff.</h1>

            <CardContainer>
                <BodyCardWrong>
                    <BodyCardTitle>SOWs</BodyCardTitle>
                    <BodyCardTitle>Agreements, disagreements</BodyCardTitle>
                    <BodyCardTitle>Mis-interpretations</BodyCardTitle>
                </BodyCardWrong>
                <BodyCardWrong>
                    <BodyCardTitle>Scope Management</BodyCardTitle>
                    <BodyCardTitle>"It's not in the SOW"</BodyCardTitle>
                    <BodyCardTitle>CRs</BodyCardTitle>
                </BodyCardWrong>
                <BodyCardWrong>
                    <BodyCardTitle>Burn Management</BodyCardTitle>
                    <BodyCardTitle>Blended Rates</BodyCardTitle>
                    <BodyCardTitle>Travel Costs</BodyCardTitle>
                </BodyCardWrong>
            </CardContainer>

            <p>Engage us to experience a distinctive approach that cuts out the BS and delivers what you need.</p>

            <p>When implemented with skill, and attention to architectural cleanliness, IBM Sterling can be just as nimble and aerobatic as a newly born microservices system.</p>

            <p>Intrigued? Get in touch with us now.</p>
        </Body>

    </Page>

export default Home