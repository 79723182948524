import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Label, Input, TextArea, Submit, HumanFormFillerCheck } from '../components/StyledFormComponents'
import { AcknowledgementModal } from '../components/AcknowledgementModal'

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = 
        { isAckModalOpen: false
        , isConnectError: false
        , isVerificationError: false
        , name: ''
        , company: ''
        , email: ''
        , message: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleModalAck = this.handleModalAck.bind(this);
    }

    componentDidMount() {
        this.hydrateStateWithSessionStorage()
    }

    hydrateStateWithSessionStorage() {
        let value = null;
        this.setState(
            { name: !(value = sessionStorage.getItem('name'))? '' : value
            , company: !(value = sessionStorage.getItem('company'))? '' : value
            , email: !(value = sessionStorage.getItem('email'))? '' : value
            , message: !(value = sessionStorage.getItem('message'))? '' : value
            }
        )
    }

    clearSessionStorage() {
        sessionStorage.removeItem('name');
        sessionStorage.removeItem('company');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('message');
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });

        if(event.target.name === 'verification') {
            this.setState({isVerificationError: false});
        }

        sessionStorage.setItem(event.target.name, event.target.value)
    }

    handleSubmit(event) {
        event.preventDefault();

        const url = window.location.href.replace(window.location.pathname, '/connect');

        //Deliberately named the opposite. If this is filled, we caught the bot. Exit silently.
        if (this.state.humancheck) { 
            this.props.history.push('/'); 
            this.clearSessionStorage(); 
            return; 
        }

        if (!isVerifiedHuman(this.state.verification)) {
            this.setState({isVerificationError: true});
            return;
        }

        fetch(url, {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                // 'Accept': 'application/json',
                'Accept': 'text/plain',
                'Content-Type': 'application/json'
            },
        }).then(response => {
            response.text().then(data => {
                this.setState({isConnectError: (response.status===200? false : true)});
                this.setState({ isAckModalOpen: true });
            })
        }).catch((error) => {
            console.error(error);
        });
    }

    handleModalAck() {
        this.setState({ isConnectError: false, isAckModalOpen: false });
        if (!this.state.isConnectError) {
            this.clearSessionStorage();
            this.props.history.push('/');
        }
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Label htmlFor='name'>Your Name</Label>
                <Input type='text' name='name' id='name' required onChange={this.handleChange} value={this.state.name}/>

                <Label htmlFor='company'>Your Company</Label>
                <Input type='text' name='company' id='company' required onChange={this.handleChange} value={this.state.company}/>

                <Label htmlFor='email'>Your Email</Label>
                <Input type='email' name='email' id='email' required onChange={this.handleChange} value={this.state.email}/>

                <Label htmlFor='verification'>3+3+3=?</Label>
                <Input type='text' name='verification' id='verification' required placeholder='Please answer, to help us fight spam.' onChange={this.handleChange} errorState={this.state.isVerificationError}/>

                <Label htmlFor='message'>Tell us a bit about your situation, needs, and desires</Label>
                <TextArea name='message' id='message' onChange={this.handleChange} value={this.state.message}/>

                <Submit type='submit' value='Send' />

                <HumanFormFillerCheck name='humancheck' />

                <AcknowledgementModal isOpen={this.state.isAckModalOpen} ackHandler={this.handleModalAck} isConnectError={this.state.isConnectError}/>
            </Form>
        );
    }
}

function isVerifiedHuman(answer) {
    if(answer === null || answer === undefined) return false;
    if( answer === '9' ) return true;
    if( answer.localeCompare('nine', 'en', {sensitivity: 'base'}) === 0 ) return true;
    return false;
}

export default withRouter(Contact)