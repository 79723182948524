import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import LogoLink from './LogoLink'
import { EmailUs } from './EmailUs'

const StyledFooter = styled.footer`
    background-image: linear-gradient(to right, white, white 10%, #fca210);
    // background-image: linear-gradient(to right, white, white 10%, #ff9933);
    // background-color: #faa522;
    // height: 100px;
    margin-top: 50px;

    display: grid;
    grid-template-columns: 1;

    @media (min-width: 640px) {
        grid-template-columns: auto auto;
}

`
const StyledLogoBlock = styled.div`
    display: inline-block;
    margin: auto;
    position: relative;
    margin-bottom: 5px;
    margin-top: 1.5em;

    @media (min-width: 640px) {
        margin-top: 40px;
        margin-right: 5px;
    }
`

const FooterConnectLink = styled(Link)`
    display: inline-block;
    visibility: ${props => (props.pathname === '/contact' || props.pathname === '/login')? 'hidden' : 'visible'};
    font-size: 1.2rem;
    color: #6a1f01;
    margin: auto;
    padding-top: 0.5em;
    // padding-bottom: 1.5em;
    text-align: center;
    text-decoration: none;
    border-bottom: 1px solid #6a1f01;
    :hover {
        color: #6a1f01;
        transition: ease-in opacity 0.1s;
        opacity: 0.7;
    }

    :active {
        // color: #faa522;
        font-style: italic;
    }

    @media (min-width: 640px) {
        padding: 0px 0px;
        margin-left: 10px;
        margin-top: 0px;
    }
`
const EmailBlock = styled.div`
    display: ${props => (props.pathname === '/login')? 'none' : 'inline-block'};
    margin-top: 1em;
    align-self: center;
    justify-self: center;
    grid-column: 1;

    @media (min-width: 640px) {
        justify-self: left;
        margin-top: 0px;
        margin-left: 10px;
    }
`

const Footer = (props) =>
    <StyledFooter>
        <FooterConnectLink to='/contact' pathname={props.location.pathname}>Start a conversation with us right away</FooterConnectLink>
        <EmailBlock pathname={props.location.pathname}>
            <EmailUs>or email us directly</EmailUs>
        </EmailBlock>
        <StyledLogoBlock>
            <LogoLink />
        </StyledLogoBlock>
    </StyledFooter>

export default withRouter(Footer)