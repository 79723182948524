import React, { Component } from 'react';
import styled from 'styled-components'
import { BrowserRouter, Route } from "react-router-dom";
import Home from './pages/Home'
import About from './pages/About'
import How from './pages/How'
import Contact from './pages/Contact'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Login from './pages/Login'
import ScrollToTop from './components/ScrollToTop';

const Page = styled.div`
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;

    @media (min-width: 640px) {
    }
`

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Page>
          <Navbar />
          <Route exact path='/' component={Home} />
          <Route path='/about' component={About} />
          <Route path='/how' component={How} />
          <Route path='/contact' component={Contact} />
          <Route path='/login' component={Login} />
          <Footer />
        </Page>
        <ScrollToTop />
      </BrowserRouter>
    );
  }
}

export default App;
