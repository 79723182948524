import styled from 'styled-components'

export const Form = styled.form`
    margin-top: 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    display: grid;
    justify-content: center;
    
    @media (min-width: 640px) {
    }
`
export const Label = styled.label`
    display: inline-block;
    font-size: 1em;
    color: #6a1f01;
    margin-bottom: 0.5em;
`

export const Input = styled.input`
    display: inline-block;
    font-size: 1em;
    
    border-width: 1px;
    border-style: ${props => props.errorState? 'solid': 'none'};
    border-color: ${props => props.errorState? 'red': 'initial'};
    
    border-bottom: 1.5px solid #6a1f01;
    width: 20em;
    margin-bottom: 2em;
    :focus {
        outline: none;
    }
`
export const HumanFormFillerCheck = styled.input`
    display: none;
`

export const TextArea = styled.textarea`
    font-size: 1em;
    border-style: none;
    border-top: 1.5px solid #6a1f01;
    border-left: 1.5px solid #6a1f01;
    width: 25em;
    height: 10em;
    :focus {
        outline: none;
    }
`

export const Submit = styled.input`
    display: inline-block;
    border: none;
    background-color: #6a1f01;
    color: papayawhip;
    font-size: 1em;
    width: 5em;
    height: 2em;
    margin-top: ${props => props.marginTop? props.marginTop : '3em'};
    :hover {
        text-decoration: underline;
        opacity: 0.8;
    }
    :focus {
        outline: none;
    }
    :active {
        width: 4.9em;
        height: 1.9em;
    }
`

export const ErrorMessage = styled.p`
    display: inline-block;
    width: 25em;
    margin-top:20px;
    font-size: 0.8em;
    color: red;
    visibility: ${props => props.loginError? 'visible' : 'hidden'};
`
export const AdditionalMessage = styled.p`
    display: inline-block;
    width: 25em;
    margin-top:1em;
    font-size: 0.8em;
    visibility: ${props => props.loginError ? 'visible' : 'hidden'};
`
export const Spacer = styled.div`
    display: ${props => props.displaySpacer? 'inline-block' : 'none'};
    width: 25em;
    height: ${props => props.spacerHeight};
`