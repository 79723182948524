import React from 'react'
import styled from 'styled-components'

const Page = styled.div`
    display: grid;
    // grid-auto-flow: dense;
    grid-template-columns: 1fr;
    grid-column-gap: 4px;

    @media (min-width: 640px) {
        // grid-template-columns: 40vw calc(100% - 40vw);
        grid-template-columns: 2fr 10fr;
    }
`
const BannerImage = styled.img`
    // object-fit: cover;
    max-width: 100%;
    // width: 100%
    // height: 32vw;    
    grid-column: 1;
    display: none;
    @media (min-width: 640px) {
        grid-row: span 2;
        display: inline-block;
    }
`
const Banner = styled.h1`
    // background-image: linear-gradient(to right, #6a1f01, #6a1f01 20%, #ff9933);
    // background-image: linear-gradient(to right, white, white 70%, #ff9933);
    // color: papayawhip;

    border-top: solid;
    border-top-color: #6a1f01;
    border-top-width: 2px;
    border-bottom: solid;
    border-bottom-width: 2px;
    border-bottom-color: #ff9933;

    color: #6a1f01;
    padding: 0em 0.5rem;

    grid-column: 1;

    // height: calc(2rem + ((1vw - 0.2em) * 1));
    line-height: calc(2rem + ((1vw - 0.2em) * 1));

    font-size: calc(1rem + ((1vw - 0.2em) * 1));
    margin-top: 0;
    margin-bottom: 0.2em;
    font-weight: 400;

    @media (min-width: 640px) {
        grid-column: 2;
        padding: 0em 0.5rem;
        font-size: calc(1rem + ((1vw - 0.2em) * 1));
`

const Body = styled.section`
    text-align: justify;
    padding: 0.2em 2rem;
    grid-column: 1;
    margin-bottom: 5em;
    margin-right: 2rem;
    
    
    @media (min-width: 640px) {
        padding: 0.2em 0.5rem;
        grid-column: 2;
    }
`
const StyledOrderedList = styled.ul`
    margin-top: 0.8em;
    line-height: 2;
`

const StyledOrderedListItem = styled.li`
    list-style-type: decimal;
    font-family: Consolas;
    font-size: 0.8em;
    font-weight: 500;
    color: #6a1f01;
`
const StyledUnorderdList = styled.ul`
    margin-top: 0.8em;
    line-height: 2;
`

const StyledUnorderedListItem = styled.li`
    list-style-type: square;
    font-family: Consolas;
    font-size: 0.8em;
    font-weight: 500;
    color: #6a1f01;
`

const Footnote = styled.p`
    display: inline-block;
    font-size: 0.8em;
    color: grey;
    border-top: solid;
    border-color: grey;
    border-width: 1px;
    width: 25em;
`

const How = () =>
    <Page>
        <BannerImage src='images/pudding-700w.jpg' />
        <Banner>The proof of the pudding, is in the eating</Banner>
        <Body>
            <p>
                We aim to upend the traditional manner in which enterprise software solutions are created and offered. Much along the lines of SaaS (Software as a Service).
            </p>

            <p>
                We are strongly of the philosophy that you pay for software after you see it manifest, and see it meeting your requirements. This is in stark contrast to the traditional SI (Systems Integrator) and "Consulting" models where you pay and pray.
            </p>

            <p>
                At the same time, IBM Sterling Order Management implementations can span expansive functionality that takes time to implement, and needs to be shaped to your unique requirements. In order to bridge this gap we adopt lean and agile philosophies and methods. This approach when, blended with our secret sauce, enables us to offer compelling prices for software that adds value immediately.
            </p>
        </Body>

        <BannerImage src='images/agile-700w.jpg' />
        <Banner>Lean & Agile</Banner>
        <Body>
            <p>
                We are focused on getting business value to be realized as soon as possible. Consequently, our MO is to discern and build out functionality to get you live as soon as possible with an MVP, while building out a framework for continuous delivery of functionality.
            </p>

            <p>
                Embrace change. Expect change. Deploy useable functionality that delivers value immediately. Then, continue to change/grow the solution to enable most valuable functionality first.
            </p>
        </Body>

        <BannerImage src='images/pencils-ready-700w.jpg' />
        <Banner>Simple Steps to Satisfaction</Banner>
        <Body>
            <p>
                Order, taste, subscribe, enjoy.
            </p>

            <p>
                It's as simple as that: give us your requirements; taste our solution; subscribe to onTap, enjoy.
            </p>

            <p>
                Once you get in touch with us, we will have a few quick conversations where we will determine if we are a fit for your needs, and you decide that you do want to try out our approach. Following this, your path to having a live implemented solution will take these simple steps:
            </p>

            <StyledOrderedList>
                <StyledOrderedListItem>We work with you to understand your requirements, and system landscape. [2 weeks]</StyledOrderedListItem>
                <StyledOrderedListItem>We envision and agree on the initial solution (MVP) that we will build out, and deploy.</StyledOrderedListItem>
                <StyledOrderedListItem>We build it; you test it; and if you choose to, start your monthly subscription payments to start Sterling onTap. [4 weeks]</StyledOrderedListItem>
            </StyledOrderedList>

            <p>
                Following this we will continue to understand your requirements and roll out features in an agile cadence. Your cost will remain the same for any features that are not Major Features.
            </p>

            <p>
                For Major Features, we will follow the same 3 step procedure laid out above, so that you know ahead of time what it will cost you. You get to taste it and make sure that you like it, before you decide to subscribe and pay for it.
            </p>
        </Body>

        <BannerImage src='images/journey-700w.jpg' />
        <Banner>Journey beyond the MVP</Banner>
        <Body>
            <p>
                We expect our MVP to consist of taking orders, processing payments, and coordinating with your fulfillment systems. It will also include item and inventory ingestion along with inventory visibility publication for your order capture systems to consume.
            </p>
            <p>
                Many outfits that purport to be lean and agile, will take you to an MVP, and then grind down to an exceedingly slow pace; often falling back to traditional approaches and their attendant sloth-like pace of delivering functionality.
            </p>

            <p>
                Our approach is significantly different. Because we operate in a subscription model, we realize that you can choose to end your subscription at any time<sup>*</sup>. It is in our interest to ensure that we are constantly keeping you happy.
            </p>

            <Footnote>
                <sup>*</sup>1-month notice required to stop service.
            </Footnote>
        </Body>

        <BannerImage src='images/whats-big-700w.jpg' />
        <Banner>What's in the pour? What's Major?</Banner>
        <Body>
            <p>
                Features such as those below will be implemented to your requirements, and rolled out as features that will be included in your subscription:
            </p>

            <StyledUnorderdList>
                <StyledUnorderedListItem>Inventory Visibility</StyledUnorderedListItem>
                <StyledUnorderedListItem>Store Pickup</StyledUnorderedListItem>
                <StyledUnorderedListItem>Ship from Store</StyledUnorderedListItem>
                <StyledUnorderedListItem>Pricing & Promotions</StyledUnorderedListItem>
                <StyledUnorderedListItem>Returns (basic)</StyledUnorderedListItem>
            </StyledUnorderdList>

            <p>
                The following features are examples of being a Major Feature:
            </p>

            <StyledUnorderdList>
                <StyledUnorderedListItem>Supply side solution (Purchase Orders)</StyledUnorderedListItem>
                <StyledUnorderedListItem>Complex logistics - transfer orders, integration with carrier systems</StyledUnorderedListItem>
                <StyledUnorderedListItem>Major Store/Call Center App requirements</StyledUnorderedListItem>
                <StyledUnorderedListItem>Sterling CPQ</StyledUnorderedListItem>
                <StyledUnorderedListItem>Complex Reverse Logistics</StyledUnorderedListItem>
            </StyledUnorderdList>
        </Body>
    </Page >

export default How