import React, { Component } from 'react'
import styled from 'styled-components'
import { Submit } from './StyledFormComponents'
import { EmailUs } from './EmailUs'


const Mask = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    display: grid;
    justify-content: center;
    align-items: top;
    grid-auto-rows: min-content;

    background-color: rgba(0, 0, 0, 0.6);

    visibility : ${props => props.isOpen ? 'visible' : 'hidden'};
    z-index: ${props => props.isOpen ? 1000 : -1};
`

const Modal = styled.div`
    visibility : ${props => props.isOpen ? 'visible' : 'hidden'};
    justify-items: center;
    align-items: center;
    text-align: center;
    line-height: 1.5;
    margin-top: 5em;
    box-shadow: 0 0 5px rgba(0, 0, 0, .05), 0 0px 5px rgba(0, 0, 0, .08);

    background-color: white;
    width: 20em;
`

const ContentContainer = styled.div`
    display: block;
    padding-top: 0.5em;
    padding-bottom: 1em;

`

const MessageText = styled.p`
    margin-bottom: 1em;
`

export class AcknowledgementModal extends Component {
    constructor(props) {
        super(props)

        this.handleKeyUp = this.handleKeyUp.bind(this)
    }

    componentDidMount() {
        window.addEventListener("keyup", this.handleKeyUp, false);
    }

    componentWillUnmount() {
        window.removeEventListener("keyup", this.handleKeyUp, false);
    }

    handleKeyUp(e) {
        const keys = {
            27: () => {
                e.preventDefault();
                this.props.ackHandler()
                window.removeEventListener("keyup", this.handleKeyUp, false);
            }
        };

        if (keys[e.keyCode]) {
            keys[e.keyCode]();
        }
    }

    render() {
        console.log(`AcknowledgementModal rendering with props.isOpen value of: ${this.props.isOpen}`)
        console.log(`AcknowledgementModal rendering with props.isConnectError value of: ${this.props.isConnectError}`)
        if (this.props.isConnectError) return (
            <Mask isOpen={this.props.isOpen}>
                <Modal isOpen={this.props.isOpen} >
                    <ContentContainer>
                        <MessageText>Apologies. We could not capture your information at this time.</MessageText>
                        <MessageText>Please send us direct email.</MessageText>
                        <EmailUs />
                        <Submit type='submit' value='OK' onClick={this.props.ackHandler} marginTop='1em' />
                    </ContentContainer>
                </Modal>
            </Mask>
        )
        else return (
            <Mask isOpen={this.props.isOpen}>
                <Modal isOpen={this.props.isOpen} >
                    <ContentContainer>
                        <MessageText>Thank you!</MessageText>
                        <MessageText>We will be in touch with you soon.</MessageText>
                        <Submit type='submit' value='OK' onClick={this.props.ackHandler} marginTop='1em' />
                    </ContentContainer>
                </Modal>
            </Mask>
        )
    }
}

