import React from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import LogoLink from './LogoLink'

const Nav = styled.nav`
    display: grid;
    padding-top: 10px;
    padding-bottom: 10px;
    grid-template-columns: 1;

    @media (min-width: 640px) {
        grid-template-columns: auto auto;
        padding-top: 10px;
    }
`

const StyledLogoBlock = styled.div`
    display: inline-block;
    margin: auto;
    margin-bottom: 1.5em;
    
    @media (min-width: 640px) {
        margin-left: 5px;
    }
`
const NavList = styled.ul`
    display: inline-block;
    margin: auto;

    padding: 0px 0px;

    grid-row: 2;

    @media (min-width: 640px) {
        grid-row: 1;
        grid-column: 2;

        margin-right: 5vw;
    }
`

const NavItem = styled(Link)`
    display: block;
    text-align: center;
    font-size: 0.8em;
    // font-weight: 600;
    font-weight: ${props => props.currentpath === props.to? 100 : 600};
    color: #6a1f01;
    text-transform: uppercase;
    padding: 10px 10px;

    text-decoration: none;
    // border-left: ${props => props.currentpath === props.to? '1px solid #6a1f01' : 'none'};
    opacity: ${props => props.currentpath === props.to? 0.8 : 1};
    pointer-events: ${props => props.currentpath === props.to? 'none' : 'auto'};
    border-bottom: none;
    transition: border 200ms ease;
    transition: ease-in opacity 200ms;


    :hover {
        color: #6a1f01;
        transition: ease-in opacity 200ms;
        opacity: 0.8;
        border-bottom: 1px solid #6a1f01;
        transition: border 200ms ease; 
    }
    
    :active {
        // color: #faa522;
        font-style: italic;
    }

    @media (min-width: 420px) {
        display: inline;
    }
`

const Navbar = props =>
    <Nav>
        <StyledLogoBlock>
            <LogoLink />
        </StyledLogoBlock>
        <NavList>
            <NavItem to='/about' currentpath={props.location.pathname}>About Us</NavItem>
            <NavItem to='/how' currentpath={props.location.pathname}>Our Model</NavItem>
            <NavItem to='/contact' currentpath={props.location.pathname}>Contact Us</NavItem>
            <NavItem to='/login' currentpath={props.location.pathname}>Client Login</NavItem>
        </NavList>
    </Nav>

export default withRouter(Navbar)