import React from 'react'
import { Redirect } from 'react-router-dom'
import { Form, Label, Input, Submit, ErrorMessage, AdditionalMessage, Spacer } from '../components/StyledFormComponents'

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            loginError: false,
            isLoggedin: false,
            doLoginResponse: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();
        let authenticated = false;

        const url = window.location.href.replace(window.location.pathname, '/doLogin');

        fetch(url, {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                // 'Accept': 'application/json',
                'Accept': 'text/plain',
                'Content-Type': 'application/json'
            },
        }).then(response => {
            response.text().then(data => {
                this.setState({doLoginResponse : data})
                authenticated = (response.status === 200 ? true : false)
                if (authenticated) this.setState({ loginError: false, isLoggedin: true })
                else this.setState({ loginError: true, isLoggedin: false })
            })
        }).catch((error) => {
            console.error(error);
        });
    }

    render() {
        // alert('isLoggedin is ' + this.state.isLoggedin);
        if (this.state.isLoggedin) return (<Redirect to='/' />);

        return (
            <Form onSubmit={this.handleSubmit}>
                <Label htmlFor='loginEmail'>Email</Label>
                <Input type='email' name='email' id='loginEmail' required onChange={this.handleChange} />

                <Label htmlFor='loginPassword'>Password</Label>
                <Input type='password' name='password' id='loginPassword' required onChange={this.handleChange} />

                <Submit type='submit' value='Login' />
                <ErrorMessage loginError={this.state.loginError}>Invalid login.</ErrorMessage>
                <AdditionalMessage loginError={this.state.loginError}>{this.state.doLoginResponse}</AdditionalMessage>
                <Spacer spacerHeight='4em' displaySpacer={!this.state.loginError}/>
            </Form>

        );
    }
}

export default Login