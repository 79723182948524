import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const StyledLink = styled(Link)`
    display: inline-block;
    // font-size: 1.1rem;
    font-weight: 550;
    color: #6a1f01;
    text-decoration: none;
    box-shadow: inset 0px -1px 0px 0px #6a1f01;

    :hover {
        // text-decoration: underline;
        // border-bottom: 1px solid #6a1f01;
        box-shadow: inset 0px -1px 0px 0px #6a1f01;
        color: #6a1f01;
        transition: ease-in opacity 0.1s;
        opacity: 0.7;
    }

    :active {
        // color: #faa522;
        font-style: italic;
    }
`

const InlineLink = (props) =>
        <StyledLink to={props.to}>
            {props.children}
        </StyledLink>

export default InlineLink