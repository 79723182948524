import React from 'react'
import styled from 'styled-components'

const EmailIconBlock = styled.a`
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 5px 5px;
    template-grid-columns: 1;
    text-decoration: none;

    :hover {
        transition: ease-in opacity 0.1s;
        opacity: 0.7;
    }

    :active {
        font-style: italic;
    }

`

const EmailImage = styled.img`
    width: 30px;
    height: 14px;
`    
const EmailCTA = styled.p`
    color: #6a1f01;
    font-size: 0.7em;
    text-align: center;
    text-justify: center;
    margin: 0;
    padding: 0;
`

function getEmailRef() {
    // 'mailto:connect-@sterlingontap.com?subject=I need Sterling onTap'
    const z = {a : 'mail', b : 'to', c:':', d:'connect', e:'-', f:'2hn4@', g:'sterling', h:'ontap', i:'.', j:'com'
            , k:'?', l:'sub', m:'ject', n:'=I need ', o:'Sterling ', p:'on', q:'Tap' }
    return z.a+z.b+z.c+z.d+z.e+z.f+z.g+z.h+z.i+z.j+z.k+z.l+z.m+z.n+z.o+z.p+z.q;
}

export const EmailUs = (props) =>
    <EmailIconBlock href={getEmailRef()}>
        <EmailCTA>{props.children}</EmailCTA>
        {/* <EmailCTA>connect@sterlingontap.com</EmailCTA> */}
        <EmailImage src='images/mail.svg'/>
    </EmailIconBlock>

