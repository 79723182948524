import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const StyledLink = styled(Link)`
    // display: grid;
    // background-image: linear-gradient(to right, #6a1f01, #6a1f01 100%, #ff9933);
`

const LogoLink = () =>
        <StyledLink to='/'>
            <img
                alt="Home"
                src='images/sterlingontap.svg'
                width="120px"
                height="40px"
            />
        </StyledLink>

export default LogoLink